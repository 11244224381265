import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/australia-products.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);


const BlogDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">
                  Australian products at your doorstep from anywhere, anytime
                </Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>Nov 21, 2020
                  </Text>
                  <Text>Michael Tippett
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-4">
          <Container>
            <Row>
              <Col lg="12" className="mb-5">
                {/* <!-- Blog section --> */}
                <Post>
                  <div>
                    <img src={titleImage} alt="Australia Products" className={styles.titleImage} />
                  </div>
                  <div>
                    <p>Australia can provide some of the best shopping experiences and high-grade products compared to anywhere else around the globe, especially online. Alas, not everyone lives in Australia and not every website ships outside of Australia. Next time you’re distraught after having your heart set on a product but not being able to ship it to your address, kick away the anguish with our services. Buy anything your heart desires whether it’s needed at your home, office, or loved one’s house. All this in just three simple steps.</p>
                    <p><strong>Pick your online stores</strong></p>
                    <p>Pick out your favourite online store or multiple stores that ship to Australia or are based there. The variety and choices are endless really. From personal use items to professional-grade work equipment you just cannot find in your own country, you can have it all under your belt with just a few simple clicks here and there. No effort required, order just like you would order as if you were sitting in Australia and stock up your cart with goodies and merchandise! Make sure you know the store’s terms and conditions of shipping, including anything you need to do from your side, and buy from a reliable store.</p>
                    <p><strong>We’ll ship instantaneously or combine many parcels into one!</strong></p>
                    <p>Use a reputable, dependable parcel forwarding company which you can trust your goods with such as ours to arrange for the next step. We can not only forward your package to you right away, but will also allow you to make huge savings on your money spent and combine various packages from different stores into one parcel! After all, the important thing is to give you the best value possible so we keep your options completely open between ultra-fast shipping or smartly saving bucks! After arranging how many parcels you want to have forwarded, confirm the international or local shipping costs so you know exactly how much you pay.</p>
                    <p><strong>And we’re off!</strong></p>
                    <p>As soon as you’re satisfied with your package deal, you can easily send the package to yourself at the address of your choosing, whether it’s your own, your workplace’s or a friend’s. We have an array of customisable options so you can achieve the perfect balance between delivery speed and value for money according to your own liking. We even have many promotions and discount deals running so make sure you avail those opportunities and take full advantage of them!</p>
                    <p>So here it is, the easiest way to order anything to your doorstep without the need to scour through hundreds of websites in an attempt to find a store that will deliver worldwide. No matter where you are, we’ve got your back. It’s a simple, speedy, and cost-effective solution to all your online shopping dilemmas whether it be geographical constraints or the cost of multiple packages being sent as opposed to one. Rest assured that your parcel will be in the safest and secure hands with our dedicated logistics team always working to provide the utmost paramount services, every single time.</p>
                    <a href="https://members.hotsnail.com.au/signup">Sign up to HotSnail now</a>
                  </div>
                </Post>
                <Box className="d-flex" mt={4}>
                  <BadgePost>Australian-Products</BadgePost>
                  <BadgePost>Shopping</BadgePost>
                  <BadgePost>Mail forwarding</BadgePost>

                </Box>

              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogDetails;
